<template>
  <div class="graph-container">
    <div :id="contentId" class="graph-content">
      <div class="analytics-title">Analytics Graph</div>
      <div id="analyticsGraph" style="width: 100%; height: 500px"></div>
      <div v-if="loading" class="analytics-content-loading">
        <md-progress-spinner
          class="graph-loading-spinner"
          md-mode="indeterminate"
          :md-stroke="2"
          :md-diameter="30"
        ></md-progress-spinner>
      </div>
    </div>
    <div class="graph-details">
      <v-select
        class="data-points-select"
        :items="dataPointsRange"
        :value="numberOfPoints"
        label="Number of data points"
        outlined
        dense
        hide-details
        @change="onClickDataPoints"
      ></v-select>
      <div
        v-for="graphType in graphTypesArray"
        class="graph-type-container"
        :key="graphType.value"
      >
        <div class="graph-type-content">
          <div
            class="graph-type-circle"
            :style="`background-color: ${graphType.color};`"
          ></div>
          <div style="text-align: left">{{ graphType.name }}</div>
        </div>
        <v-checkbox
          v-model="graphType.checked"
          color="primary"
          @change="onClickCheckbox"
          :disabled="
            graphTypesArray.filter((graphType) => graphType.checked).length ===
              1 && graphType.checked
          "
          hide-details
        ></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { hexToRGBA, isLightColor } from "@/utils/helper";

export default {
  name: "AnalyticsGraph",
  props: {
    contentId: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    numberOfPoints: {
      type: Number,
      default: null,
    },
    dataPointsRange: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.createAnalyticsGraph();
  },
  data() {
    return {
      chart: null,
      renderedSeriesCount: 0,
      graphTypes: {
        registered_companies: {
          name: "Registered Companies",
          checked: true,
          value: "registered_companies",
          color: "#0F123F",
          data: [],
        },
        verified_companies: {
          name: "Verified Companies",
          checked: false,
          value: "verified_companies",
          color: "#FE981E",
          data: [],
        },
        non_verified_companies: {
          name: "Non Verified Companies",
          checked: false,
          value: "non_verified_companies",
          color: "#FE1E1E",
          data: [],
        },
        paid_companies: {
          name: "Paid Companies",
          checked: false,
          value: "paid_companies",
          color: "#221EFE",
          data: [],
        },
        created_collections: {
          name: "Created Collections",
          checked: false,
          value: "created_collections",
          color: "#1EA0FE",
          data: [],
        },
        completed_collections: {
          name: "Completed Collections",
          checked: false,
          value: "completed_collections",
          color: "#09AA19",
          data: [],
        },
        created_styles: {
          name: "Created Styles",
          checked: false,
          value: "created_styles",
          color: "#54567E",
          data: [],
        },
        completed_styles: {
          name: "Completed Styles",
          checked: false,
          value: "completed_styles",
          color: "#AE1111",
          data: [],
        },
        created_dpps: {
          name: "Created DPPs",
          checked: false,
          value: "created_dpps",
          color: "#5F8C92",
          data: [],
        },
        published_dpps: {
          name: "Published DPPs",
          checked: false,
          value: "published_dpps",
          color: "#03FF4A",
          data: [],
        },
        total_products: {
          name: "Total Products",
          checked: false,
          value: "total_products",
          color: "#E7C555",
          data: [],
        },
        traceable_products: {
          name: "Traceable Products",
          checked: false,
          value: "traceable_products",
          color: "#FF00F5",
          data: [],
        },
      },
    };
  },
  watch: {
    data: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
  },
  computed: {
    graphTypesArray() {
      return Object.values(this.graphTypes);
    },
  },
  methods: {
    onClickDataPoints(value) {
      this.$emit("points-updated", value);
    },
    onClickCheckbox(value = null) {
      if (value === null || value) {
        this.$emit(
          "update",
          Object.values(this.graphTypes)
            .filter((graphType) => graphType.checked)
            .map((graphType) => graphType.value)
        );
      } else {
        this.updateAnalyticsGraph();
        this.$emit(
          "checked",
          Object.values(this.graphTypes)
            .filter((graphType) => graphType.checked)
            .map((graphType) => graphType.value)
        );
      }
    },
    updateData() {
      if (this.data && Object.keys(this.data).length) {
        for (const typeKey of Object.keys(this.data)) {
          if (this.graphTypes[typeKey]) {
            this.graphTypes[typeKey].data = this.data[typeKey];
          }
        }
      }
      this.updateAnalyticsGraph();
    },
    createAnalyticsGraph() {
      am4core.useTheme(am4themes_animated);

      this.chart = am4core.create("analyticsGraph", am4charts.XYChart);
      this.chart.logo.disabled = true;

      const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
      const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.numberFormatter = new am4core.NumberFormatter();
      valueAxis.numberFormatter.numberFormat = "#";

      this.chart.legend = null;

      this.chart.scrollbarX = null;

      this.chart.maskBullets = false;

      this.chart.cursor = new am4charts.XYCursor();
      this.chart.cursor.xAxis = dateAxis;
      this.chart.cursor.behavior = "none";
      this.onClickCheckbox();
    },
    updateAnalyticsGraph() {
      if (this.chart) {
        const checkedGraphTypes = Object.values(this.graphTypes).filter(
          (graphType) => graphType.checked
        );
        const graphValues =
          checkedGraphTypes && checkedGraphTypes.length > 0
            ? checkedGraphTypes[0].data.map((item) => {
                return {
                  date: item.date,
                };
              })
            : [];
        for (const graphValue of graphValues) {
          for (const graphType of checkedGraphTypes) {
            graphValue[graphType.value] = graphType.data.find(
              (item) => item.date === graphValue.date
            ).value;
          }
          graphValue.date = new Date(graphValue.date);
        }

        this.chart.data = graphValues;

        for (let i = this.renderedSeriesCount - 1; i >= 0; i--) {
          this.chart.series.removeIndex(i).dispose();
        }
        this.renderedSeriesCount = 0;

        for (const graphType of checkedGraphTypes) {
          const series = this.chart.series.push(new am4charts.LineSeries());
          series.dataFields.valueY = graphType.value;
          series.dataFields.dateX = "date";
          series.name = graphType.name;
          series.stroke = am4core.color(graphType.color);
          series.tooltipText = `Date: {dateX}\nCount: {valueY}`;
          series.tooltip.getFillFromObject = false;
          series.tooltip.background.fill = am4core.color(graphType.color);
          series.tooltip.label.fill = am4core.color(
            isLightColor(hexToRGBA(graphType.color, 1)) ? "#000000" : "#FFFFFF"
          );

          const bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.stroke = am4core.color(graphType.color);
          bullet.circle.fill = am4core.color(graphType.color);
          bullet.circle.strokeWidth = 0.5;
          bullet.circle.radius = 3;

          this.renderedSeriesCount++;
        }

        this.chart.xAxes.each((axis) => {
          axis.tooltip.disabled = true;
        });
        this.chart.yAxes.each((axis) => {
          axis.tooltip.disabled = true;
        });
      }
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
.graph-container {
  display: flex;
  justify-content: space-between;
  background: #eef1fa;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.graph-content {
  position: relative;
  width: 70%;
  border-right: 1px solid black;
}
.graph-details {
  width: 30%;
}
.data-points-select {
  margin: 0px 20px 10px 20px;
}
.graph-type-container {
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.graph-type-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;
}
.graph-type-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.analytics-title {
  color: #5b3d87;
  font-size: 22px;
  font-weight: bold;
}
.analytics-content-loading {
  position: absolute;
  top: 35px;
  left: 0;
  height: 500px;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.graph-loading-spinner::v-deep .md-progress-spinner-circle {
  stroke: #582963 !important;
}
</style>
