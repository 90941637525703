<template>
  <div class="subscription-listing-container">
    <div class="subscription-listing-header">
      <div class="subscription-listing-title">Subscriptions</div>
      <div class="subscription-listing-actions">
        <v-btn
          class="ma-4"
          dark
          color="primary"
          @click="openSubscriptionDialog"
        >
          Add Subscription
        </v-btn>
        <v-select
          v-model="filteredPackage"
          :items="packages"
          label="Package"
          item-text="name"
          item-value="id"
          background-color="#fff"
          @change="filterSubscriptions"
          rounded
        ></v-select>
        <v-text-field
          v-model="search"
          label="Search"
          @input="debounceSearch"
          prepend-inner-icon="mdi-magnify"
          background-color="#fff"
          rounded
        ></v-text-field>
      </div>
    </div>
    <div class="subscription-listing-content">
      <table>
        <tr>
          <th>Company Name</th>
          <th>Package</th>
          <th>
            <div>
              <div>Due date</div>
              <div @click.stop="onChangeDueOrder">
                <v-icon>{{
                  order === "asc"
                    ? "mdi-sort-calendar-ascending"
                    : "mdi-sort-calendar-descending"
                }}</v-icon>
              </div>
            </div>
          </th>
          <th>Amount</th>
        </tr>
        <tr
          v-for="subscription in subscriptions"
          :style="loading ? 'display: none' : ''"
          :key="subscription.id"
        >
          <td>
            <div>
              <div>
                <v-icon v-if="!subscription.avatar" size="40" color="#473068"
                  >mdi-domain</v-icon
                >
                <v-avatar v-else size="40">
                  <v-img :src="subscription.avatar" />
                </v-avatar>
              </div>
              <div
                :class="`status-background ${getDueClass(subscription.due)}`"
              >
                <truncate-hovering :text="subscription.name" :length="30" />
              </div>
            </div>
          </td>
          <td :class="`status-background ${getDueClass(subscription.due)}`">
            {{ subscription.package }}
          </td>
          <td :class="`status-background ${getDueClass(subscription.due)}`">
            {{ subscription.due }}
          </td>
          <td>
            <div>
              <div
                :class="`status-background ${getDueClass(subscription.due)}`"
              >
                {{ subscription.amount }}
              </div>
              <div @click="deleteSubscription(subscription.id)">
                <v-icon class="delete-icon">mdi-trash-can-outline</v-icon>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="loading">
          <td colspan="4">
            <div class="subscription-loading-container">
              <md-progress-spinner
                class="subscription-loading-spinner"
                md-mode="indeterminate"
                :md-stroke="2"
                :md-diameter="30"
              ></md-progress-spinner>
            </div>
          </td>
        </tr>
      </table>
      <div v-if="!loading">
        <div v-if="totalCount">
          <pagination
            :current-page="currentPage"
            :total-count="totalCount"
            :per-page-count="perPageCount"
            :max-visible-pages="maxVisiblePages"
            @load-page="loadPage"
          />
        </div>
        <div v-else>
          <div class="subscription-empty">No subscriptions found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";
import debounce from "lodash/debounce";

export default {
  name: "SubscriptionListing",
  components: {
    Pagination,
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
    packages: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    perPageCount: {
      type: Number,
      default: 10,
    },
    maxVisiblePages: {
      type: Number,
      default: 5,
    },
  },
  watch: {
    currentPage() {
      this.page = this.currentPage;
    },
  },
  data() {
    return {
      filteredPackage: null,
      search: "",
      page: 1,
      order: "asc",
    };
  },
  methods: {
    openSubscriptionDialog() {
      this.$emit("new");
    },
    onChangeDueOrder() {
      this.order = this.order === "asc" ? "desc" : "asc";
      this.requestSubscriptionsFetch();
    },
    requestSubscriptionsFetch() {
      this.$emit(
        "fetch",
        {
          package: this.filteredPackage,
          search: this.search,
          order: this.order,
        },
        this.page
      );
    },
    loadPage(page) {
      this.page = page;
      this.requestSubscriptionsFetch();
    },
    debounceSearch: debounce(function () {
      this.requestSubscriptionsFetch();
    }, 500),
    filterSubscriptions() {
      this.requestSubscriptionsFetch();
    },
    deleteSubscription(id) {
      this.$emit(
        "delete",
        {
          package: this.filteredPackage,
          search: this.search,
          order: this.order,
        },
        this.page > 1 && this.subscriptions.length === 1
          ? this.page - 1
          : this.page,
        id
      );
    },
    getDueClass(due) {
      const dueDate = new Date(due);
      const currentDate = new Date();
      const diffTime = dueDate - currentDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const diffMonths = Math.ceil(diffDays / 30);
      if (diffDays < 0) {
        return "overdue";
      } else if (diffMonths <= 3) {
        return "due-soon";
      } else {
        return "paid";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-listing-container {
  background: #eef1fa;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.subscription-listing-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.subscription-listing-title {
  color: #5b3d87;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}
.subscription-listing-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.subscription-listing-content {
  width: 100%;
  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0px 10px;

    tr > th {
      color: #828282;
      .v-icon {
        cursor: pointer;
        color: #473068;
      }
    }

    tr > th > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    tr > th,
    tr > td {
      width: calc(100% / 4);
      height: 0px;
      min-height: 0px;

      div {
        height: 100%;
        display: flex;
        align-items: center;
        color: #473068;
      }
    }

    tr {
      th:first-child {
        text-align: left;
      }
      td:first-child > div {
        justify-content: flex-start;
      }
      td:first-child > div > div:last-child {
        justify-content: flex-start;
      }
      td:first-child > div > div:last-child.status-background {
        border-radius: 10px 0 0 10px;
        text-align: left;
        word-wrap: anywhere;
      }
      th:last-child {
        text-align: right;
        padding-right: 30px;
      }
      td:last-child > div {
        justify-content: flex-end;
      }
      td:last-child > div > div:first-child {
        justify-content: flex-end;
      }
      td:last-child > div > div:first-child.status-background {
        border-radius: 0 10px 10px 0;
      }
    }

    tr:not(:first-child) {
      td:first-child > div,
      td:last-child > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
.status-background {
  flex: 1;
  height: 100%;
  padding: 10px;
}
.overdue {
  background-color: #ff0000;
}
.due-soon {
  background-color: #ea7a66;
}
.paid {
  background-color: #61e085;
}
.subscription-loading-container {
  display: flex;
  justify-content: center !important;
  align-items: center;
  width: 100%;
}
.subscription-loading-spinner::v-deep .md-progress-spinner-circle {
  stroke: #582963 !important;
}
.subscription-empty {
  margin: 20px;
}
.delete-icon {
  cursor: pointer;
}
.delete-icon:hover {
  font-size: 30px;
}
</style>
