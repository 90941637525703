<template>
  <div class="heat-map-container">
    <div class="heat-map-title">{{ title }}</div>
    <div class="heat-map-content" :style="`${loading ? 'display: none' : ''}`">
      <div class="heat-map-data-container">
        <div id="heatmap" style="width: 100%; height: 500px"></div>
      </div>
      <div class="heat-map-legend-container">
        <div class="heat-map-legend">
          <div class="heat-map-legend-header">
            <div>{{ countryLegendTitle }}</div>
            <div>{{ countLegendTitle }}</div>
          </div>
        </div>
        <div v-if="countriesData && countriesData.length">
          <div
            class="heat-map-legend"
            v-for="(data, index) in countriesData.slice(
              (currentPage - 1) * perPageCount,
              currentPage * perPageCount
            )"
            :key="index"
          >
            <div class="heat-map-legend-content">
              <div>{{ data.country }}</div>
              <div>{{ data.count }}</div>
            </div>
            <md-progress-bar
              class="heat-map-bar"
              md-mode="determinate"
              :md-value="(data.count / sumCount) * 100"
              :md-buffer-value="100"
            ></md-progress-bar>
          </div>
          <div v-if="totalCount > perPageCount">
            <pagination
              :current-page="currentPage"
              :total-count="totalCount"
              :per-page-count="perPageCount"
              :max-visible-pages="maxVisiblePages"
              @load-page="loadPage"
            />
          </div>
        </div>
        <div v-else>
          <div class="heat-map-no-data">No data available</div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="heat-map-content-loading">
      <md-progress-spinner
        class="map-loading-spinner"
        md-mode="indeterminate"
        :md-stroke="2"
        :md-diameter="30"
      ></md-progress-spinner>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import countryList from "country-list";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Pagination from "./Pagination.vue";

export default {
  name: "HeatMap",
  components: {
    Pagination,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    countryLegendTitle: {
      type: String,
      required: true,
    },
    countLegendTitle: {
      type: String,
      required: true,
    },
    countriesData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    perPageCount: {
      type: Number,
      default: 10,
    },
    maxVisiblePages: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    countriesData: {
      handler() {
        this.updateHeatMap();
      },
    },
    deep: true,
  },
  mounted: function () {
    this.createHeatMap();
  },
  computed: {
    sumCount() {
      return this.countriesData.reduce((acc, item) => acc + item.count, 0);
    },
  },
  methods: {
    createHeatMap() {
      am4core.useTheme(am4themes_animated);

      this.chart = am4core.create("heatmap", am4maps.MapChart);

      this.chart.geodata = am4geodata_worldLow;

      this.chart.projection = new am4maps.projections.Miller();

      this.chart.seriesContainer.draggable = false;
      this.chart.seriesContainer.resizable = false;
      this.chart.chartContainer.wheelable = false;

      this.chart.zoomControl = new am4maps.ZoomControl();
      this.chart.zoomControl.slider.height = 0;
      this.chart.zoomControl.plusButton.disabled = true;
      this.chart.zoomControl.minusButton.disabled = true;

      this.chart.logo.disabled = true;

      const polygonSeries = this.chart.series.push(
        new am4maps.MapPolygonSeries()
      );

      polygonSeries.useGeodata = true;

      const heatmapSeries = this.chart.series.push(
        new am4maps.MapPolygonSeries()
      );
      heatmapSeries.useGeodata = true;
      heatmapSeries.calculateVisualCenter = true;
      heatmapSeries.data = this.countriesData.map((item) => ({
        id: countryList.getCode(item.country),
        value: item.count,
      }));

      const polygonTemplate = heatmapSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}: {value}";
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.strokeWidth = 0.5;
      polygonTemplate.stroke = am4core.color("#5B3D87");

      polygonTemplate.events.on("over", (event) => {
        event.target.isHover = false;
      });

      heatmapSeries.heatRules.push({
        property: "fill",
        target: polygonTemplate,
        min: am4core.color("#DDD4EB"),
        max: am4core.color("#5B3D87"),
      });

      polygonTemplate.fill = am4core.color("#ffffff");

      this.chart.homeZoomLevel = 1;
      this.chart.homeGeoPoint = { latitude: 0, longitude: 0 };
      this.chart.goHome();
    },
    updateHeatMap() {
      if (this.chart) {
        const heatmapSeries = this.chart.series.values[1];
        heatmapSeries.data = this.countriesData.map((item) => ({
          id: countryList.getCode(item.country),
          value: item.count,
        }));
      }
    },
    disposeHeatMap() {
      if (this.chart) {
        this.chart.dispose();
      }
    },
    loadPage(page) {
      this.$emit("load-page", page);
    },
  },
  beforeDestroy() {
    this.disposeHeatMap();
  },
};
</script>
<style lang="scss" scoped>
.heat-map-container {
  background: #eef1fa;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.heat-map-title {
  color: #5b3d87;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}
.heat-map-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.heat-map-content-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.heat-map-data-container {
  position: relative;
  width: 70%;
  margin-right: 20px;
}
.heat-map-legend-container {
  width: 30%;
  margin-bottom: 20px;
}
.heat-map-legend {
  margin-bottom: 20px;
}
.heat-map-legend-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #828282;
  font-weight: bold;
}
.heat-map-legend-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.heat-map-no-data {
  color: #828282;
  font-size: 14px;
  text-align: center;
}
.heat-map-bar {
  margin-top: 10px;
}
.heat-map-bar::v-deep .md-progress-bar-track:after {
  background-color: #582963 !important;
}
.md-progress-bar.md-theme-default.md-determinate {
  background-color: white !important;
}
.md-progress-bar.md-theme-default.md-indeterminate,
.md-progress-bar.md-theme-default.md-query {
  background-color: #c6a8ec !important;
  background-color: var(--md-theme-default-primary-on-, #c6a8ec) !important;
}
.heat-map-bar::v-deep .md-progress-bar-fill:after {
  background-color: #582963 !important;
}
.map-loading-spinner::v-deep .md-progress-spinner-circle {
  stroke: #582963 !important;
}
</style>
